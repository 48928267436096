import { useContext } from 'react';
import { MODE } from '@havenengineering/module-owners-shared-library/dist/components/Let2OwnBreakSummary';

import { LettingsContext } from '../../../contexts/lettings';
import { useScreenWidth } from '../../../hooks/useScreenWidth';
import { Let2OwnSummary, ParkPeakType } from '../../helpers/bookings';
import { Let2OwnBreaksSummaryWrapper } from '../Let2OwnBreakSummaryWrapper/Let2OwnBreakSummaryWrapper';
import { Let2OwnRenewalCompletedBanner } from '../Let2OwnRenewalCompleteBanner/Let2OwnRenewalCompleteBanner';
import styles from './CalendarBanner.module.scss';

interface CalendarBannerProps {
  let2OwnSummary?: Let2OwnSummary;
  loading: boolean;
  skipPayment?: boolean;
  breaksNotSavedAlertEnabled?: boolean;
}

export const CalendarBanner: React.FC<CalendarBannerProps> = ({
  let2OwnSummary,
  loading,
  skipPayment = false,
  breaksNotSavedAlertEnabled = false,
}) => {
  const currentDate = new Date().toISOString();
  const mobileView = useScreenWidth(960);

  const { lettingSummary, isLet2OwnRenewal } = useContext(LettingsContext);

  const maxBreakList = () => {
    const getMaxBreaksForPeakType = (peakType: ParkPeakType) => {
      if (!let2OwnSummary?.summary) return 0;

      return (
        let2OwnSummary.summary[peakType].midweek.max +
        let2OwnSummary.summary[peakType].weekend.max
      );
    };

    return (
      <div className={styles.maxBreakList}>
        <span>Maximum number of breaks:</span>
        {`${getMaxBreaksForPeakType(
          ParkPeakType.PEAK
        )} peak, ${getMaxBreaksForPeakType(
          ParkPeakType.OFF_PEAK
        )} off peak, ${getMaxBreaksForPeakType(
          ParkPeakType.SUPER_PEAK
        )} super peak`}
      </div>
    );
  };

  if (lettingSummary?.let2Own && let2OwnSummary?.summary) {
    if (isLet2OwnRenewal) {
      return (
        <>
          <Let2OwnBreaksSummaryWrapper
            isLoading={loading}
            let2OwnSummary={let2OwnSummary}
            currentDate={currentDate}
            mode={MODE.RENEWAL}
            skipPayment={skipPayment}
            breaksNotSavedAlertEnabled={breaksNotSavedAlertEnabled}
          />
          {maxBreakList()}
        </>
      );
    }

    return (
      <>
        <Let2OwnRenewalCompletedBanner
          currentDate={currentDate}
          loading={loading}
          let2OwnSummary={let2OwnSummary}
          mobileview={mobileView}
        />
        {maxBreakList()}
      </>
    );
  }

  return <></>;
};
